import { ref, watch } from 'vue'
import { useUserProfileQuery } from './queries/useUserProfileQuery'
import { useUserProjectDisplaySettingsQuery } from './queries/useUserProjectDisplaySettingsQuery'
import { UserSettings } from '~/types/types'

export function useUserProjectSettings() {
   const { data: userProfile } = useUserProfileQuery()
   const { data: settings } = useUserProjectDisplaySettingsQuery()

   const result = ref({} as UserSettings)

   watch(
      [userProfile, settings],
      () => {
         if (userProfile.value && settings.value) {
            result.value = {
               isAdmin: userProfile.value.isAdmin,
               isDemo: settings.value.isDemoModeEnabled,
               isAdminNotImpersonatingRegularUser: userProfile.value.isAdmin && !settings.value.isUserImpersonationEnabled
            } as UserSettings
         }
      },
      { immediate: true, deep: true }
   )
 
   return result
}
